<template>
    <v-col>
        <H1>CRM</H1>

    </v-col>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'IconsEcommerceWeb',
    data: () => ({

    }),
    computed: {
        ...mapState('access', ['idActionsRol'])
    },
    mounted() {




    }
}

</script>